@charset "utf-8"
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700')
@import "~@ng-select/ng-select/themes/default.theme.css"
@import '~bootstrap/dist/css/bootstrap.min.css'

/* Top Level Definitions */
html, body
    height: 100%
    font-family: Roboto, sans-serif
body
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
    font-family: Roboto, sans-serif
    margin: auto
    width: 1366px

body, html, h1, h2, h3, h4, h5, h6, p, a, input, header
    font-weight: 400
    color: #53565a
    padding: 0
h1
    font-size: 26px
    font-weight: 400
    color: #53565a
header
    color: #53565a
    font-weight: 400
    font-size: 14px

.form-control
    height: 34px !important
    border: 0.5px solid #939CA5 !important
    border-radius: 0 !important
    font-size: 14px !important
    padding-left: 3px !important
    padding-right: 3px !important
tbody
    .form-control
        height: 20px !important

.form-control:focus
    border: 0.5px solid #939CA5 !important
    box-shadow: 0 0 0 0 !important

.btn-custom-secondary
    border: 1px solid #3f84c5
    color: #3f84c5
    padding: 6px 20px 6px 20px
    border-radius: 3px
    cursor: pointer
    font-size: 14px
    display: inline-block
    text-align: center
    white-space: nowrap
    height: 35px
    min-width: 150px
    width: fit-content
    background-color: #fff

.btn-custom-secondary:focus
    box-shadow: 0 0 0 0 !important

.btn-custom-primary
    border: 1px solid #3f84c5
    color: #fff
    padding: 6px 20px 6px 20px
    border-radius: 0.2rem
    cursor: pointer
    display: inline-block
    text-align: center
    white-space: nowrap
    background-color: #3f84c5
    font-size: 14px
    height: 35px
    min-width: 150px
    width: fit-content

.link-button
    color: #3f84c5
    font-size: 14px
    font-weight: 400
    cursor: pointer
    text-decoration: none
    border: none
    background-color: transparent
    button: focus
        outline: none

.w-148
    width: 148px

.btn-custom-primary:focus
    box-shadow: 0 0 0 0 !important
.btn:focus
    box-shadow: 0 0 0 0 !important

.cursor-pointer
    cursor: pointer !important

a, button
    cursor: pointer
    text-decoration: none
h1
    font-size: 26px

.custom-hr
    .custom-hr
    color: #979797
    height: 3px
    overflow: visible
    margin-top: 18px
    margin-bottom: 18px
    align-self: center
    width: 100%

.font-size-18
    font-size: 18px
a
    font-size: 14px
    font-weight: 400
    color: #3f84c5
a:hover
    text-decoration: none !important
    color: #3f84c5

.value-label
    font-size: 14px
    height: 21px

.header-label
    font-weight: 700
    font-size: 14px

.font-red
    color: Red
    font-size: 14px

.details-page-main-outer
    display: flex
    flex-direction: column
    padding: 13px 15px
    height: inherit
    margin-bottom: 20px
    background-color: white
    box-shadow: 0 0 3px 1px #dee0dd
    border: 1px solid #bac1c8
    border-radius: 5px
    text-decoration: none
    width: 1310px

// Min Height for iPad Screens

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape)
    body
        min-height: 1028px

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: potrait)
    body
        min-height: 1028px

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)
    body
        min-height: 1028px

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait)
    body
        min-height: 1028px

// ng-select styling

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked
    background-color: #007bff !important
    color: #ffffff !important

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child
    border-bottom-right-radius: 0 !important
    border-bottom-left-radius: 0 !important
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child
    border-radius: 0 0 0 0 !important
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked
    background-color: #fff

.ng-dropdown-panel
    font-size: 14px
    z-index: 1070 !important
    // width: auto !important
    // min-width: 100% !important
.basic-custom-class
    .ng-dropdown-panel
        width: auto !important

.ng-value
    font-size: 14px
    margin-right: 0.2rem
table
    .ng-value
        font-size: 13px !important

.ng-placeholder
    font-size: 13px !important
    color: #53565a !important

.ng-dropdown-panel
    .ng-dropdown-panel-items
        .ng-option
            white-space: normal !important

.ng-select:focus
    border: 0.5px solid #939CA5 !important

.ng-dropdown-panel
    .ng-dropdown-panel-items
        .ng-option
            padding: 0px 7px 2px 3px

.ng-select .ng-select-container
    min-height: 34px
    font-size: 13px
    max-height: 34px
    color: #53565a !important

.ng-select.ng-select-single .ng-select-container
    height: 34px

.ng-select .ng-select-container
    border: 0.5px solid #939CA5
    border-radius: 0

.ng-dropdown-panel.ng-select-bottom
    border: 1px solid #939CA5
    border-bottom-right-radius: 0
    border-bottom-left-radius: 0

.ng-dropdown-panel.ng-select-top
    border-top-right-radius: 0
    border-top-left-radius: 0

.ng-select
    .ng-select-container
        .ng-value-container
            padding-left: 2px !important
            border-radius: 0 0 0 0 !important
.basic-search-multi-select
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked
        background-color: #007bff !important
        color: #ffffff !important

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked
    // background-color: #007bff !important
    // color: #ffffff !important
    border-radius: 0 0 0 0 !important

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label
    font-weight: 400 !important

.ng-select.ng-select-opened>.ng-select-container .ng-arrow
    border-color: transparent transparent #3f84c5

.ng-select .ng-arrow-wrapper .ng-arrow
    border-color: #3f84c5 transparent transparent

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container
    box-shadow: 0 0 0 0 !important

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon
    display: none
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value
    background-color: transparent
    margin-bottom: 2px

.ng-select.ng-select-multiple .ng-select-container .ng-value-container
    padding-top: 0px

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder
    position: relative

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label
    padding: 1px 1px

.ng-select-multiple
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked
        background-color: transparent
        color: #53565a !important

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option
    color: #53565a !important
    font-size: 14px !important

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input
    top: 0px !important

// html
//     .ng-dropdown-panel-items
//         max-height: 120px !important

// Reducing ng-select dropdown panel height
.effective-date
    .ng-dropdown-panel-items
        max-height: 300px !important
.ng-dropdown-panel-items
    max-height: 300px !important
    font-family: Roboto, sans-serif

//this is for removing arrows from input type number
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    margin: 0
input[type=text]
    height: 34px
    box-sizing: border-box
input[type=number]
    height: 34px
    box-sizing: border-box

.main-container
    background-color: #F9FCF8
    padding: 20px
    border-bottom: 0
    border-left: 1px solid #bac1c8
    border-right: 1px solid #bac1c8
    min-height: calc(100vh - 94px)

    // height: 100%

//Modal popup

.modal
    top: 13% !important

.modal-lg
    max-width: 70% !important
.modal-sm
    max-width: 60% !important

.modal-backdrop
    height: 100% !important
    width: 100% !important

.modal-content
    border: 0 !important
    border-radius: 0 !important
    padding-right: 20px !important
    padding-left: 20px !important
    padding-top: 20px !important
    padding-bottom: 0 !important
    max-height: calc( 100vh - 170px)
    overflow-y: auto
    overflow-x: auto

.modal-header
    border-bottom: 0 !important
    padding: 0px !important
    justify-content: space-between !important

.modal-footer
    padding-left: 0 !important
    padding-right: 0 !important
    padding-top: 0 !important
    padding-bottom: 20px !important
    border: 0 !important

.modal-body
    padding-left: 0 !important
    padding-right: 0 !important
    padding-top: 0 !important
    padding-bottom: 20px !important

.modal-header
    img
        cursor: pointer
        height: 18px
        width: 18px
    .modal-title
        font-size: 26px !important

.modal-holder
    animation-name: example
    animation-duration: 0.5s

.modal-holder-sm
    width: 60%
    left: auto

.modal-holder-sm .modal-sm .modal-content
    height: 300px

@keyframes example
    0%
        transform: scale(0.5)
    100%
        transform: scale(1)

.max-width-60
    max-width: 60% !important

.popover
    height: auto !important
    width: 186px !important
    font-family: Roboto, sans-serif
    white-space: normal

.warning-text
    color: red !important

.basic-search-multi-select
    width: auto !important

.basic-custom-class
    width: auto !important

.disclaimer-text
    font-family: Tahoma, Arial, Sans-Serif
    font-size: 13px
    padding-bottom: 10px

.error-message
    color: red

// Margin bottom add new physician for iPad Screens

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape)
    .margin-bottom-25
        margin-bottom: 25px !important

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: potrait)
    .margin-bottom-25
        margin-bottom: 25px !important

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)
    .margin-bottom-25
        margin-bottom: 25px !important

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait)
    .margin-bottom-25
        margin-bottom: 25px !important

ng-select.ng-select-disabled>.ng-select-container
    background-color: #e7e7e7 !important

.fit-content
    width: fit-content
    block-size: fit-content

button:disabled
    opacity: 0.5
    cursor: not-allowed
    pointer-events: unset

.table > :not(caption) > * > *
    border-bottom-width: 0 !important